import * as Sentry from "@sentry/browser";
import { captureConsoleIntegration } from '@sentry/integrations'

if (process.env.NODE_ENV === "production") {
    Sentry.init({
        dsn: "https://72dce93ebdd84cd8aec9dc7dc4beb97b@o4503901975674880.ingest.sentry.io/4503901975674880",
        integrations: [
          Sentry.browserTracingIntegration(),
          captureConsoleIntegration({ levels: ['error'] })
        ],
        tracesSampleRate: 1.0,
    });
}
